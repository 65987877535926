<template>
  <div class="table">
    <DragTable :header="header" :list-query="list">
      <template #ctl="{row}">
        <span class="word-color" @click="edit(row)">编辑</span>
      </template>
    </DragTable>
    <options></options>
  </div>
</template>
<script>
import { defineComponent, reactive, watch } from '@vue/composition-api'
import options from  './components/new_coupon.vue'

export default defineComponent({
  components: { options },
  setup(props, { root }) {
    const header = [
      { name: '配置项', value: 'name' },
      { name: '活动管理', slot: 'ctl' }
    ]
    const list = reactive({
      data: []
    })
    const getList = () => {
      root.$axios.get(`/setup/setupActivityFixed/index`)
      .then(res=>{
        list.data = res.data.result
      })
    }
    getList()
    watch(()=>root.$store.state.common.childWindow.show,(val)=>{
      if(!val) {
        getList()
      }
    })
    const edit = e => {
      root.$store.commit('childWindow', {show: true, data: JSON.parse(JSON.stringify(e))})
    }

    return {
      header,
      list,
      edit
    }
  },
})
</script>
<style lang="scss" scoped>

</style>