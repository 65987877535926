<template>
  <child-window ref="cw" class="window">
    <div class="header-top">
      <h2>企业微信绑定-新人优惠券发放</h2>
      <div>
        <el-button type="primary" @click="cancel">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </div>
    <div class="content">
      <h3 class="content-title">提示文本配置（领券提示）（100个字符上限）</h3>
      <el-input class="textarea" type="textarea" v-model="params.content.notice_text" placeholder="请输入领券提示" maxlength="100"></el-input>
      <h3 class="content-title">优惠券配置（最多配置5套）</h3>
      <div class="flex">
        <p class="inp">优惠券名称（30个字符上限）</p>
        <p class="inp">优惠券兑换码（每个输入框限1个）</p>
      </div>
      <div class="flex" style="margin-top: 20px;" v-for="(item,i) in params.content.coupon_setup" :key="i">
        <el-input class="inp" maxlength="30" placeholder="请输入优惠券名称（用户端展示）" v-model="item.name"></el-input>
        <el-input class="inp" placeholder="请输入兑换码" v-model="item.exchange_code"></el-input>
      </div>
    </div>
  </child-window>
</template>
<script>
import { defineComponent, reactive, watch } from '@vue/composition-api'
import Textarea from '../../../../../components/common/Textarea.vue'

export default defineComponent({
  components: { Textarea },
  setup(props, { root, refs }) {
    const info = reactive(root.$store.state.common.childWindow.data)
    const params = reactive({
      setup_activity_fixed_id: info.setup_activity_fixed_id,
      key: info.key,
      content: {
        notice_text: '',
        coupon_setup: [
          { name: '', exchange_code: '' },
          { name: '', exchange_code: '' },
          { name: '', exchange_code: '' },
          { name: '', exchange_code: '' },
          { name: '', exchange_code: '' },
        ]
      }
    })
    if(info.content) {
      params.content = info.content
      const num = params.content.coupon_setup.length
        if(num < 5) {
          for(let i = 0; i<5-num;i++) {
            params.content.coupon_setup.push({ name: '', exchange_code: '' })
          }
        }
    }
    watch(()=>root.$store.state.common.childWindow.show, val => {
      if(val) {
        params.setup_activity_fixed_id = root.$store.state.common.childWindow.data.setup_activity_fixed_id
        params.key = root.$store.state.common.childWindow.data.key
        params.content = root.$store.state.common.childWindow.data.content
        const num = params.content.coupon_setup.length
        if(num < 5) {
          for(let i = 0; i<5-num;i++) {
            params.content.coupon_setup.push({ name: '', exchange_code: '' })
          }
        }
      }
    })

    const close = () => {
      Object.keys(params).forEach(e=>{
        if(e.includes('btn')) {
          params[e] = false
        } else {
          params[e] = ''
        }
      })
      refs.cw.close()
    }
    const cancel = async () => {
      const confirm = await root.$goDialog('是否放弃本次配置')
      if(!confirm) return
      close()
    }
    const save = () => {
      const data = JSON.parse(JSON.stringify(params))
      if(!data.content.notice_text) {
        root.$message.warning('请输入提示文本')
        return
      }
      let flag = false
      let flag2 = false
      data.content.coupon_setup.forEach(item=>{
        if(item.name) {
          flag = true
        }
        if((item.name && !item.exchange_code) || (!item.name && item.exchange_code)) {
          flag2 = true
        }
      })
      if(!flag) {
        root.$message.warning('最少输入一个优惠券')
        return
      }
      if(flag2) {
        root.$message.warning('优惠券信息不完整，缺少对应的昵称或兑换码')
        return
      }
      root.$axios.put(`/setup/setupActivityFixed/update`, data)
      .then(res=>{
        root.$message.success(res.msg)
        close()
      })
    }

    return {
      params,
      cancel,
      save,
    }
  },
})
</script>
<style lang="scss" scoped>
.header-top{
  padding-top: 20px;
  flex-shrink: 0;
}
.window{
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
}
.flex-item{
  width: 100%;
  margin-bottom: 50px;
  &>.name{
    width: 200px;
    flex-shrink: 0;
  }
}
.flex{
  display: flex;
  align-items: center;
  &>span{
    flex-shrink: 0;
    width: 80px;
  }
}
.content{
  height: 100%;
  padding-bottom: 50px;
  padding-right: 300px;
  overflow-y: auto;
  ::v-deep textarea{
    height: 200px;
  }
}
.content-title{
  margin-top: 20px;
}
.inp{
  width: 45%;
  margin-right: 5%;
}
</style>